export const w = typeof window !== "undefined" ? window : undefined;

enum Feature {
  ANALYTICS_LOG = "analytics-log",
}

const toggles: Record<Feature, boolean> = {
  [Feature.ANALYTICS_LOG]: false,
};

export const isEnabled = (feature: Feature): boolean =>
  w ? typeof toggles[feature] !== "undefined" && !!localStorage.getItem(`ft-${feature}`) : false;

export const logEnabled = (): boolean => isEnabled(Feature.ANALYTICS_LOG);

export const snakeCase = (str: string) => str.toLocaleLowerCase().replace(/ /g, "_");

type NestedObject = {
  [key: string]: any;
};

// This normalizes values for any event pushed to window.dataLayer.
export const convertToSnakeCase = (obj: NestedObject): NestedObject => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertToSnakeCase(item));
  }

  const snakeCaseObj: NestedObject = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      snakeCaseObj[key] = snakeCase(obj[key]);
    } else {
      snakeCaseObj[key] = convertToSnakeCase(obj[key]);
    }
  }

  return snakeCaseObj;
};
