import { rule } from "@telia/b2x-url-segments";
import type { TeliaEvent, TeliaEventName, TeliaEventParams } from "./types";
import { w, logEnabled, convertToSnakeCase } from "./util";

declare global {
  interface Window {
    dataLayer?: TeliaEvent[];
  }
}

export type { TeliaEvent, TeliaEventName, TeliaEventParams };

export const track = (eventName: TeliaEventName, eventParams: TeliaEventParams) => {
  if (!w) {
    return;
  }

  const eventObject = {
    event: eventName,
    ...convertToSnakeCase(eventParams),
  };

  if (logEnabled()) {
    // eslint-disable-next-line no-console
    console.table(eventObject);
  }

  w.dataLayer = w?.dataLayer || [];
  w.dataLayer.push(eventObject);
};

export const getDefaultEventParameters = (url: URL) => ({
  page_type: getPageType(url),
  page_segment: getPagesegment(url),
  business_area: getBusinessArea(url),
});

const getBusinessArea = (url: URL) => {
  return url.pathname.startsWith("/foretag") ? "b2b" : "b2c";
};

const getPageType = (url: URL) => {
  const r = rule(url);
  const pageType = "unknown";

  if (
    r.pathMatches([/^\/foretag\/mybusiness\/\d{9}\/?$/]) ||
    r.pathMatchesExact(["/", "/foretag"])
  ) {
    return "start";
  }

  if (
    r.pathMatches([/^\/foretag\/mybusiness\/\d{9}\/bestall/]) ||
    r.pathMatches([
      /^\/foretag\/bredband\/(?:anpassa|bekraftelse|bestall|kassa|organisationsval|till-kassan)/,
    ])
  ) {
    return "order";
  }

  if (r.pathMatches([/^\/foretag\/mybusiness\/\d{9}\/hantera/])) {
    return "manage";
  }

  if (r.pathMatches([/^\/foretag\/mybusiness\/\d{9}\/support/])) {
    return "support";
  }

  if (r.pathStartsWith(["/sok", "/foretag/sok"])) {
    return "search";
  }

  if (r.pathMatches([/^\/foretag\/telia-at-work\/support/])) {
    return "support";
  }

  if (r.pathMatches([/^\/foretag\/telia-at-work/])) {
    return "subscription";
  }
  if (r.pathStartsWith(["/foretag/support"])) {
    return "support";
  }

  return pageType;
};

const getPagesegment = (url: URL) => {
  const r = rule(url);
  let pageSegment = "open_pages";

  if (typeof url.pathname === "undefined") {
    return "";
  }

  if (r.pathStartsWith(["/foretag/mybusiness"])) {
    pageSegment = "mybusiness";
  } else if (r.pathStartsWith(["/mitt-telia", "/privat/mitt-telia"])) {
    pageSegment = "mitt_telia_web";
  } else if (r.pathStartsWith(["/support", "/foretag/support", "/kontakt"])) {
    pageSegment = "open_pages_support";
  } else if (r.pathStartsWith(["/foretag/telia-at-work"])) {
    pageSegment = "telia_at_work";
  }

  return pageSegment;
};
